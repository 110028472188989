import { Autoplay, FreeMode, Manipulation, Navigation, Pagination, Swiper, Thumbs } from "swiper";
import AutoplayPauseOnFocus from "./swiper/AutoplayPauseOnFocus";
import FixTabIndex from "./swiper/FixTabIndex";
import LazyImages from "./swiper/LazyImages";
const fourthBreakpoints = {
    500: {
        slidesPerView: 2,
        slidesPerGroup: 2,
    },
    700: {
        slidesPerView: 3,
        slidesPerGroup: 3,
    },
    1000: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 40,
    },
};
const swiperDefaults = {
    speed: 500,
    spaceBetween: 24,
};
export default function initSliders() {
    makeBannersSlider();
    makeBrandsSlider();
    makeReviewsSwiper();
    makeProdCarouselSwiper();
    makeDiscountsSliders();
}
function makeBrandsSlider() {
    makeSwiper('.brands-slider', [Autoplay, LazyImages, FreeMode], {
        loop: true,
        speed: 8000,
        freeMode: {
            enabled: true,
            momentumVelocityRatio: 0.75,
        },
        spaceBetween: 8,
        slidesPerView: 'auto',
        autoplay: {
            delay: 1,
            pauseOnMouseEnter: true,
        },
        on: {
            autoplayStop: swiper => swiper.autoplay.start(),
        }
    }, false);
}
function makeDiscountsSliders() {
    makeSwiper('disposable', [Navigation, LazyImages, FreeMode], { freeMode: true, slidesPerView: "auto" });
    makeSwiper('cumulative', [Navigation, LazyImages, FreeMode], { freeMode: true, slidesPerView: "auto" });
}
function makeReviewsSwiper() {
    makeSwiper('reviews', [Navigation, LazyImages], {
        slidesPerView: 1.2,
        breakpoints: fourthBreakpoints,
        on: {
            resize: reviewsAutoShowMoreButton,
            afterInit: reviewsAutoShowMoreButton,
        }
    });
}
function reviewsAutoShowMoreButton(swiper) {
    swiper.slides.forEach(slide => {
        const moreButton = slide.querySelector('.review-teas__more');
        const textElement = slide.querySelector('.review-teas__text');
        if (textElement.scrollHeight > textElement.clientHeight) {
            moreButton.classList.remove('hidden');
        }
        else {
            moreButton.classList.add('hidden');
        }
    });
}
export function makeProductThumbsSlider() {
    return makeSwiper('#prod-nav-swiper', [Navigation, LazyImages, FreeMode, Manipulation], {
        freeMode: true,
        slidesPerView: 'auto',
        spaceBetween: 40,
        watchSlidesProgress: true,
    });
}
export function makeProductSlider(thumbsSwiper) {
    return makeSwiper('#prod-item-swiper', [Thumbs, LazyImages, Manipulation], {
        thumbs: {
            swiper: thumbsSwiper,
            autoScrollOffset: 0,
        },
    });
}
export function makeBannersSlider() {
    return makeSwiper('.hero-slider', [Autoplay, Pagination, LazyImages], {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 4,
        speed: 1000,
        pagination: {
            el: '.hero-pagination',
        },
    });
}
export function makeTagsSlider() {
    return makeSwiper('#prod-articles-swiper', [Pagination, LazyImages], {
        spaceBetween: 24,
        pagination: {
            el: ".prod-articles__pagination",
        },
    });
}
export function makeSimilarProductsCarousel() {
    return makeProdCarouselSwiper('prod-similar');
}
export function makeProdCarouselSwiper(container = 'prod') {
    return makeSwiper(container, [Navigation, LazyImages], {
        slidesPerView: 1.2,
        breakpoints: fourthBreakpoints,
    });
}
function makeSwiper(container, modules = [], options = {}, autoPause = true) {
    const builder = getOptions(options);
    let name = 'swiper';
    if (typeof container === 'string' && container.match(/^[a-z_-]+$/)) {
        name = container;
        container = `#${name}-swiper`;
    }
    for (const module of modules) {
        if (module === Autoplay) {
            builder.addAutoplay(autoPause);
        }
        else if (module === Pagination) {
            builder.addPagination();
        }
        else if (module === Navigation) {
            builder.addNavigation(name);
        }
        else {
            builder.addModule(module);
        }
    }
    return new Swiper(container, builder.getOptions());
}
function getOptions(options = {}) {
    options = Object.assign(Object.assign({}, swiperDefaults), options);
    if (!options.modules) {
        options.modules = [];
    }
    const addModule = (module) => {
        options.modules.push(module);
        return this;
    };
    const addAutoplay = (pauseOnFocus = true) => {
        addModule(Autoplay);
        if (pauseOnFocus) {
            addModule(AutoplayPauseOnFocus);
        }
        options = Object.assign(Object.assign({}, options), { autoplay: Object.assign({ delay: 5000, pauseOnMouseEnter: true }, makeSpreadable(options.autoplay)) });
        return this;
    };
    const addNavigation = (name = 'swiper') => {
        addModule(Navigation);
        options.navigation = {
            enabled: true,
            prevEl: `.${name}-button-prev`,
            nextEl: `.${name}-button-next`,
        };
        return this;
    };
    const addPagination = () => {
        addModule(Pagination);
        options.pagination = Object.assign({ el: '.swiper-pagination', clickable: true, renderBullet: (_index, className) => `<button class="${className}"></button>` }, makeSpreadable(options.pagination));
        return this;
    };
    addModule(FixTabIndex);
    const getOptions = () => options;
    function makeSpreadable(options) {
        return options && typeof options !== 'boolean' ? options : {};
    }
    return {
        addModule,
        addAutoplay,
        addNavigation,
        addPagination,
        getOptions,
    };
}
