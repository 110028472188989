export default function FixTabIndex({ extendParams, on }) {
    extendParams({
        watchSlidesProgress: true,
    });
    on('init', fixTabIndex);
    on('slideChangeTransitionStart', fixTabIndex);
    on('slideChange', fixTabIndex);
    function fixTabIndex(swiper) {
        for (const element of swiper.wrapperEl.querySelectorAll('.swiper-slide-visible [tabindex="-1"]')) {
            element.tabIndex = undefined;
        }
        let elements;
        try {
            elements = swiper.wrapperEl.querySelectorAll('.swiper-slide:not(.swiper-slide-visible) :is(a, button, input, select, textarea, details):not([tabindex="-1"])');
            for (const element of elements) {
                element.tabIndex = -1;
            }
        }
        catch (_a) {
            // Fix for old browsers (Safari)
            const slides = swiper.wrapperEl.querySelectorAll('.swiper-slide:not(.swiper-slide-visible)');
            for (const slide of slides) {
                elements = slide.querySelectorAll('a, button, input, select, textarea, details');
                for (const element of elements) {
                    if (element.tabIndex !== -1) {
                        element.tabIndex = -1;
                    }
                }
            }
        }
    }
}
