export default function AutoplayPauseOnFocus({ on }) {
    let focused = false;
    let hovered = false;
    on('afterInit', swiper => {
        if (!swiper.params.autoplay) {
            return;
        }
        swiper.el.addEventListener('mouseenter', () => {
            hovered = true;
            fixAutoplay(swiper);
        });
        swiper.el.addEventListener('mouseleave', () => {
            hovered = false;
            fixAutoplay(swiper);
        });
        for (const el of swiper.el.querySelectorAll('a, button')) {
            el.addEventListener('focus', () => {
                focused = true;
                fixAutoplay(swiper);
            });
            el.addEventListener('blur', () => {
                focused = false;
                fixAutoplay(swiper);
            });
        }
    });
    function fixAutoplay(swiper) {
        if (!swiper.autoplay.running) {
            return;
        }
        if ((hovered || focused) && !swiper.autoplay.paused) {
            swiper.autoplay.pause();
        }
        else if (!(hovered || focused) && swiper.autoplay.paused) {
            swiper.autoplay.paused = false;
            swiper.autoplay.resume();
        }
    }
}
